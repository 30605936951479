const HistoryIcon = ()=> {
    return <svg className="text-secondary hover:text-primary cursor-pointer transition-colors w-4 h-4"
                xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
        <g>
            <path
                d="M8.09961 2.00018C11.6896 2.00018 14.5996 4.91023 14.5996 8.50018C14.5996 12.0901 11.6896 15.0002 8.09961 15.0002C4.50966 15.0002 1.59961 12.0901 1.59961 8.50018H2.89961C2.89961 11.3719 5.22791 13.7002 8.09961 13.7002C10.9713 13.7002 13.2996 11.3719 13.2996 8.50018C13.2996 5.62848 10.9713 3.30018 8.09961 3.30018C6.31211 3.30018 4.73521 4.20173 3.79986 5.57518H5.49961V6.87518H1.59961V2.97518H2.89961V4.60018C4.08521 3.02068 5.97346 2.00018 8.09961 2.00018ZM8.74961 5.25018V8.23043L10.8576 10.3384L9.93781 11.2581L7.44961 8.76863V5.25018H8.74961Z"
                fill="currentColor"></path>
        </g>
        <defs>
            <clipPath id="clip0_274_5394">
                <rect width="15.6" height="15.6" fill="currentColor" transform="translate(0.299561 0.700195)"></rect>
            </clipPath>
        </defs>
    </svg>
}
export default HistoryIcon
