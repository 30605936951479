import NumberUpIcon from "./NumberUpIcon";
import React from "react";

const NumberDownOrUp = ({price, down}: { price: number, down: boolean }) => {
    return <div className="flex items-center ml-6 s768:ml-0">
        <NumberUpIcon down={down}/>
        <span
            className={`text-14 s768:text-16 s1024:text-18 s1336:text-24 s1440:text-30 font-600 s1440:leading-none ${down?'text-down':'text-up'}`}>
            {price?.toLocaleString()}</span>
    </div>
}
export default NumberDownOrUp
