import React, {useEffect, useState} from "react";
import initChart, {setSeriesData} from "../init";
import {
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
    Popover,
    PopoverButton,
    PopoverPanel
} from "@headlessui/react";
import ArrowTop from "./ArrowTop";
import ArrowDown from "./ArrowDown";
import Setting from "./Setting";
import HistoryButton from "./HistoryButton";
import {useRecoilState, useRecoilValue} from "recoil";
import {currentCryptoState} from "../store/crypto";
import NumberDownOrUp from "./NumberDownOrUp";
import {kline} from "../api";
import {addCallback, socketState} from "../store/socket";
import dayjs from "dayjs";

const Kline = () => {
    const [open,] = useState(false)
    const [currentCrypto, setCurrentCrypto] = useRecoilState<any>(currentCryptoState)
    const types = [
        {id: 1, name: 'tick'},
        {id: 2, name: '1m'},
        {id: 3, name: '5m'},
        {id: 4, name: '1h'},
        {id: 5, name: '4h'},
        {id: 5, name: '1d'},
    ]
    const [series, setSeries] = useState<any>()
    const webSocket = useRecoilValue(socketState)
    const [selectedType, setSelectedType] = useState(types[0])
    const [initFlag, setInitFlag] = useState(false)
    const mapQuote =(quote:any)=>({
        DT: new Date(quote.timestampMillis),
        Open: parseFloat(quote.open),
        Close: parseFloat(quote.close),
        High: parseFloat(quote.high),
        Low: parseFloat(quote.low),
        Volume: parseInt(quote.value, 10),
        value: parseFloat(quote.close),
        time:quote.timestampMillis/1000 // dayjs().format("HH:mm:ss")
    })
    const queryKline = async (chart:any)=>{
        const data = await kline("BTCUSD")
        setSeries(setSeriesData(data.data.map(mapQuote).reverse()))
        // chart.loadChart(null,data.data.map(mapQuote).reverse())
    }
    addCallback("kline",(data:any)=>{
        if(data.type==='AGG'){
            // chart?.updateChartData(mapQuote(data.data))

            series?.update(mapQuote(data.data))
            setCurrentCrypto({...data.data,grow:data.data.close<currentCrypto.close})
        }
    })
    useEffect(() => {
        if (!initFlag) {
            const chart = initChart()
            setInitFlag(true)
            queryKline(chart)
        }
    }, [initFlag])

    return <div
        className="flex flex-col flex-1 min-h-96 s1920:h-[560px] s2560:h-[810px] s3840:h-[1320px] relative">
        <div className="flex justify-between">
            <div className="s768:flex s768:items-center gap-4">
                <div className="relative">
                    <Popover className="relative">
                        <PopoverButton className="outline-none bg-transparent">
                            <div className="cursor-pointer">
                                <div className="flex items-center gap-1"><span
                                    className="block overflow-hidden size-6 s768:size-8 rounded-full lazy-load-image-background  lazy-load-image-loaded"><img alt=""
                                    src="https://currency-trade.s3.ap-east-1.amazonaws.com/icons/BTC.png"
                                    className="w-full h-full object-cover"/></span>
                                    <div
                                        className="text-primary text-20 s768:text-24 s1440:text-30">{currentCrypto?.symbol}
                                    </div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24"
                                         height="24" viewBox="0 0 24 24" fill="none"
                                         className="rotate-90">
                                        <path d="M10 7L15 12L10 17" stroke="currentColor"
                                              strokeWidth="2" strokeLinecap="round"
                                              strokeLinejoin="round"></path>
                                    </svg>
                                </div>
                            </div>
                        </PopoverButton>
                        <div>
                            {open ? <ArrowTop/> : <></>}
                            <PopoverPanel
                                data-open={open}
                                className="absolute z-40 bg-layer5 light:bg-white shadow rounded-2 text-secondary overflow-auto mt-[14px] p-2 s768:p-3 max-h-100">
                                <div className="w-80 text-14">
                                    <div className="grid grid-cols-3 text-secondary"
                                         style={{gridTemplateColumns: 'minmax(0px, 1fr) minmax(0px, 1fr) 80px'}}>
                                        <div className="pl-3 py-1">Pair</div>
                                        <div className="px-3 py-1 text-right">Price</div>
                                        <div className="pr-3 py-1 text-right">24h</div>
                                    </div>
                                    <div
                                        className="grid grid-cols-3 text-right bg-transparent cursor-pointer hover:bg-layer6 light:hover:bg-layer5 rounded"
                                        style={{gridTemplateColumns: 'minmax(0px, 1fr) minmax(0px, 1fr) 80px'}}>
                                        <div className="flex items-center pl-3 py-2"><span
                                            className="block overflow-hidden shrink-0 mr-1 w-5 h-5 rounded-full z-10 lazy-load-image-background  lazy-load-image-loaded"><img
                                            src="https://currency-trade.s3.ap-east-1.amazonaws.com/icons/BTC.png"
                                            alt=""
                                            className="w-full h-full object-cover"/></span>
                                            <div>BTC/USD</div>
                                        </div>
                                        <div className="text-primary px-3 py-2">{currentCrypto?.close?.toLocaleString()}</div>
                                        <div className="text-up pr-3 py-2">0.87%</div>
                                    </div>
                                </div>
                            </PopoverPanel>
                        </div>
                    </Popover>
                </div>
                <div
                    style={{
                        position: 'fixed',
                        top: '1px',
                        left: '1px',
                        width: '1px',
                        height: '0px',
                        padding: '0px',
                        margin: '-1px',
                        overflow: 'hidden',
                        clip: 'rect(0px, 0px, 0px, 0px)',
                        whiteSpace: 'nowrap',
                        borderWidth: '0px',
                        display: 'none'
                    }}></div>
                <NumberDownOrUp price={currentCrypto?.close} down={currentCrypto.grow}/>
            </div>
            <div className="flex gap-2">
                <Listbox value={selectedType} onChange={setSelectedType}>
                    <div
                        className="relative min-w-min border border-input light:bg-white flex items-center px-3 s1366:px-4 text-12 h-10 s768:h-12 !h-8 bg-layer4 rounded-3 border-none">
                        <ListboxButton
                            className="bg-transparent w-full outline-none text-12 font-600 text-secondary flex items-center justify-between gap-1">
                            <div className="truncate text-12 capitalize text-primary">{selectedType.name}</div>
                            <ArrowDown/>
                        </ListboxButton>
                        <ListboxOptions as="ul"
                                        className="absolute left-0 top-full z-40 max-h-60 min-w-full mt-2 p-2 s1366:p-3 bg-layer5 light:bg-white shadow focus:outline-none rounded-2 overflow-auto no-scrollbar text-12 leading-4 text-secondary">
                            {types.map((type) => (
                                <ListboxOption key={type.id} value={type}
                                               className={`relative select-none px-2 py-1 mt-0 mb-2 last:mb-0 cursor-pointer w-full transition-all rounded ${selectedType.id === type.id ? 'bg-layer6 light:bg-layer5' : ''}`}>
                                    <span className="block text-12 capitalize font-700 text-primary">{type.name}</span>
                                </ListboxOption>
                            ))}
                        </ListboxOptions>
                    </div>
                </Listbox>
                <Setting/>
                <HistoryButton/>
            </div>
        </div>
        <div className="w-full h-full">
            <div className="end_order"><span className="content"></span></div>
            <div className="my_trade_order"><span className="content"></span></div>
            <div className="other_order"><span className="content"></span></div>
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    position: 'relative',
                    background: 'transparent'
                }}
                last-interaction="mouse" id="chartContainer" className="stx-crosshair-cursor-on">
            </div>
        </div>
    </div>
}

export default Kline
