import React, {useEffect, useState} from "react";
import {queryOrders} from "../api";
import EventEmitter from "../EventEmitter";


const OrderPage = () => {
    const fields: any = [
        {width: 80, name: 'PERIOD', align: 'left'},
        {width: 160, name: 'PAIR'},
        {width: 80, name: 'CURRENCY'},
        {width: 90, name: 'AMOUNT'},
        {width: 125, name: 'OPEN'},
        {width: 125, name: 'CLOSED'},
        {width: 50, name: 'YIELD'},
        {width: 90, name: 'PROFIT', align: 'right'},
    ]
    const [orders, setOrders] = useState<any>(null)
    const loadOrders = async ()=>{
        const{data} = await queryOrders()
        setOrders(data?.items)
    }

    EventEmitter.on("loadOrder",()=>{
        loadOrders()
    })
    useEffect(() => {
        loadOrders()
    }, []);
    return <div className="relative min-h-50 max-h-[inherit]">
        <div className="h-full overflow-auto">
            <table
                className="table-fixed border-separate border-spacing-y-md w-full text-primary font-500 text-13">
                <thead>
                    <tr className="text-secondary">
                        {fields.map(({name, align, width}: { name: string, align: any, width: number }) => (
                            <th key={name} className="px-3 py-1 first:rounded-l last:rounded-r font-500"
                                align={align || "center"} style={{width: `${width}px`}}>{name}
                            </th>))}
                    </tr>
                </thead>
                <tbody className="relative" style={{transform: 'none'}}>
                {orders?.map((item:any,index:number)=><tr className="odd:bg-layer4" key={`${index}-order`}>
                    <td align="left"
                        className="px-3 first:rounded-l last:rounded-r truncate s768:first:rounded-l-3 s768:last:rounded-r-3 py-2">
                        <div className="flex items-center"><span
                            className="block overflow-hidden shrink-0 w-6 h-6 s768:w-7 s768:h-7 mr-1.5 rounded-2 lazy-load-image-background blur"><span
                            className="w-full h-full object-cover"
                            style={{display: 'inline-block'}}></span></span>
                            <div className="truncate">{item.period}S</div>
                        </div>
                    </td>
                    <td align="center"
                        className="px-3 first:rounded-l last:rounded-r truncate s768:first:rounded-l-3 s768:last:rounded-r-3 py-2">
                        <div className="flex items-center gap-1 justify-center">
                            <div className="flex items-center"><span
                                className="block overflow-hidden w-5 h-5 rounded-full -mr-2.5 z-10 lazy-load-image-background blur"><span
                                className="w-full h-full object-cover"
                                style={{display: 'inline-block'}}></span></span><span
                                className="block overflow-hidden w-5 h-5 rounded-full lazy-load-image-background blur"><span
                                className="w-full h-full object-cover"
                                style={{display: 'inline-block'}}></span></span>
                            </div>
                            <div
                                className="text-13 text-primary w-18 truncate">{item.symbol}
                            </div>
                            <svg xmlns="http://www.w3.org/2000/svg"
                                 className="rotate-180 text-down UpDownIcon"
                                 width="23" height="24" viewBox="0 0 23 24"
                                 fill="none">
                                <path
                                    d="M11.3998 6.24695L5.60645 12.0402L6.92637 13.3602L11.3998 8.88681L15.8731 13.3602L17.1931 12.0402L11.3998 6.24695ZM11.3998 11.5202L5.60645 17.3134L6.92637 18.6335L11.3998 14.1601L15.8731 18.6335L17.1931 17.3134L11.3998 11.5202Z"
                                    fill="currentColor"></path>
                            </svg>
                        </div>
                    </td>
                    <td align="center"
                        className="px-3 first:rounded-l last:rounded-r truncate s768:first:rounded-l-3 s768:last:rounded-r-3 py-2">USDT
                    </td>
                    <td align="center"
                        className="px-3 first:rounded-l last:rounded-r truncate s768:first:rounded-l-3 s768:last:rounded-r-3 py-2">{item.money}
                    </td>
                    <td align="center"
                        className="px-3 first:rounded-l last:rounded-r truncate s768:first:rounded-l-3 s768:last:rounded-r-3 py-2">{item.buyPrice}
                    </td>
                    <td align="center"
                        className="px-3 first:rounded-l last:rounded-r truncate s768:first:rounded-l-3 s768:last:rounded-r-3 py-2">{item.settlementPrice?item.settlementPrice:'-'}
                    </td>
                    <td align="center"
                        className="px-3 first:rounded-l last:rounded-r truncate s768:first:rounded-l-3 s768:last:rounded-r-3 py-2">{item.yieldRate}%
                    </td>
                    <td align="right"
                        className="px-3 first:rounded-l last:rounded-r truncate s768:first:rounded-l-3 s768:last:rounded-r-3 py-2">
                        <div className="text-down">{item.profit}</div>
                    </td>
                </tr>)}
                </tbody>
            </table>
        </div>
    </div>
}
export default OrderPage
