import React from 'react';
import './static/stx-chart.css'
import './App.css';
import './static/font.css';
import {Route, Routes, useNavigate} from "react-router-dom";
import Login from "./Login";
import 'react-toastify/dist/ReactToastify.css';
import EventEmitter from "./EventEmitter";
import Trade from "./Trade";


function App() {

    const navigate = useNavigate()

    EventEmitter.on("redirect",(router:string)=>{
        navigate(router)
    })

    return (
        <Routes>
            <Route>
                <Route path="/" element={<Trade/>}/>
                <Route path="/login" element={<Login/>}/>
            </Route>
        </Routes>
    );
}

export default App;
