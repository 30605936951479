import React, {useState} from "react";
import {Tab, TabGroup, TabList, TabPanel, TabPanels} from "@headlessui/react";
import TradeFilter from "./TradeFilter";
import OrderPage from "./OrderPage";

const TradeOrder = () => {
    // const isHiddenBet = useMediaQuery({query: '(max-width: 1024px)'})
    const menus = [{
        id: '1',
        name: 'active-bets',
        title: 'Active Bets'
    }, {
        id: '2',
        name: 'closed-bets',
        title: 'CloseD Bets'
    }, {
        id: '3',
        name: 'public-bets',
        title: 'Public Bets'
    }, {
        id: '4',
        name: 'leaderboard',
        title: 'LEADERBOARD'
    }]
    const [focus, setFocus] = useState(menus[0])
    return <TabGroup
        className="relative flex gap-3 s768:gap-3 s1024:gap-10 s1366:gap-25 s1920:gap-50 mb-2 flex-col items-start">
        <TabList className="relative text-secondary font-600 p-1 rounded-3 flex text-10 self-stretch bg-layer4 light:bg-black/5">
            {
                menus.map(item => (<Tab key={item.name} onClick={()=>{setFocus(item)}}
                    className={`${focus.id===item.id?'text-primary font-700':''} relative outline-none py-3 transition-colors duration-300 z-20 flex-1 px-0 leading-none`}>
                    {focus.id===item.id?<div className="absolute inset-0 z-0 rounded-2 bg-tab_selected"
                          style={{transform: 'none', transformOrigin: '50% 50% 0px', opacity: 1}}/>:<></>}
                    <div className="relative z-10">{item.title}</div>
                </Tab>))
            }
        </TabList>
        <TradeFilter/>
        <TabPanels style={{width:'100%'}}>
            <TabPanel>
                <OrderPage/>
                {/*<div className="flex-1">*/}
                {/*    <OrderPage/>*/}
                {/*</div>*/}
            </TabPanel>
        </TabPanels>
        {/*<TabPanels>*/}
        {/*    <TabPanel>Content 1</TabPanel>*/}
        {/*    <TabPanel>Content 2</TabPanel>*/}
        {/*    <TabPanel>Content 3</TabPanel>*/}
        {/*</TabPanels>*/}
    </TabGroup>
}
export default TradeOrder
