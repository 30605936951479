import NoData from "./NoData";

const NewBetOrder = () => {
    return <div className="mb-2">
        <div className="relative max-h-[inherit] min-h-50">
            <div className="h-full overflow-auto">
                <table className="table-fixed border-separate border-spacing-y-md w-full text-primary font-500 text-13">
                    <thead>
                    <tr className="text-secondary">
                        <th className="px-3 py-1 first:rounded-l last:rounded-r font-500" align="left"
                            style={{width: '80px'}}>BET
                        </th>
                        <th className="px-3 py-1 first:rounded-l last:rounded-r font-500" align="center"
                            style={{width: '90px'}}>WAGER
                        </th>
                        <th className="px-3 py-1 first:rounded-l last:rounded-r font-500" align="center"
                            style={{width: '90px'}}>P&amp;L
                        </th>
                        <th className="px-3 py-1 first:rounded-l last:rounded-r font-500" align="right"
                            style={{width: '95px'}}></th>
                    </tr>
                    </thead>
                    <tbody className="relative"></tbody>
                </table>
            </div>
            <NoData msg="No Bets"/>
        </div>
    </div>
}
export default NewBetOrder
