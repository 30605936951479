import axios from "axios";
import {toast} from "react-toastify";
import EventEmitter from "./EventEmitter";

const instance = axios.create({

})
instance.interceptors.request.use((config)=>{
    const url = config.url
    if(url!=='/open/v1/login'){
        const token = localStorage.getItem("token")
        if(!token){
            EventEmitter.emit("redirect","/login")
            return Promise.reject({ message: 'Request interrupted.' });
        }
        config.headers.Authorization = localStorage.getItem("token")
    }
    return config
})

instance.interceptors.response.use((res)=>{
    const url = res.config.url
    const {code,message,data} = res.data
    if(code!==200){
        toast(`${code}:${message}`)
        if(code===1000234){
            EventEmitter.emit("redirect","/login")
        }
    }else{
        if(url==='/open/v1/login'){
            localStorage.setItem("token",data)
        }
    }
    return res.data;
})

export default instance

