import {atom} from "recoil";

export const tokenState = atom({
    key: 'token',
    default: null,
});


export const accountState = atom({
    key: 'account',
    default:{balance:0,coin:'USD'}
});
