import LeverageIcon from "./LeverageIcon";
import HighLowIcon from "./HighLowIcon";
import UpDownIcon from "./UpDownIcon";

const MobileMenu = () => {
    return <nav className="fixed bottom-0 left-0 right-0 z-30 bg-layer4 flex items-center gap-2.5 py-1.5"
                style={{boxShadow: 'rgba(0, 0, 0, 0.25) 0px -2px 4px 0px'}}><a
        className="flex flex-col gap-1 flex-1 items-center text-success" href="/trade-center/contract">
        <LeverageIcon/>
        <div className="text-10 text-nowrap">1000 Leverage</div>
    </a><a className="flex flex-col gap-1 flex-1 items-center text-secondary" href="/trade-center/">
        <HighLowIcon/>
        <div className="text-10 text-nowrap">High Low</div>
    </a><a className="flex flex-col gap-1 flex-1 items-center text-secondary" href="/trade-center/up-down">
        <UpDownIcon/>
        <div className="text-10 text-nowrap">Up Down</div>
    </a></nav>
}
export default MobileMenu
