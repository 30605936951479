import {createChart} from 'lightweight-charts';

const chartOptions = {
    grid: {
        vertLines: {visible: false},
        horzLines: {visible: false},
    },
    watermark:{visible:false},
    timeScale:{timeVisible:true},
    localization: {dateFormat: "yyyy-MM-dd"},
    layout: {
        attributionLogo:false,
        textColor: 'white', background: {type: 'solid', color: '#292d2e'}}
};
let chart: any = null

function initChart() {
    if (chart != null) {
        return chart
    }
    // @ts-ignore
    chart = createChart("chartContainer", chartOptions);
    // const areaSeries = chart.addAreaSeries({
    //     lineColor: '#2962FF',
    //     topColor: '#2962FF',
    //     bottomColor: 'rgba(41, 98, 255, 0.28)'
    // });

    // const data = [{value: 0, time: 1642425322}, {value: 8, time: 1642511722}, {value: 10, time: 1642598122}, {
    //     value: 20,
    //     time: 1642684522
    // }, {value: 3, time: 1642770922}, {value: 43, time: 1642857322}, {value: 41, time: 1642943722}, {
    //     value: 43,
    //     time: 1643030122
    // }, {value: 56, time: 1643116522}, {value: 46, time: 1643202922}];
    //
    // areaSeries.setData(data);

    chart.timeScale().fitContent();
    // chart.updateFormatter({dateFormat:"YYYY-MM-dd"})
    return chart
}

export const setSeriesData = (data: any[]) => {
    const areaSeries = chart.addAreaSeries({
        lineColor: '#2962FF',
        topColor: '#2962FF',
        bottomColor: 'rgba(41, 98, 255, 0.28)'
    });
    areaSeries.setData(data)
    return areaSeries
}
export default initChart
