const EventEmitter: { listeners: any, emit: Function, on: Function,off:Function } = {

    listeners: {},

    emit: (key: string, data: any,context:any) => {
        const listener = EventEmitter.listeners[key]
        listener && listener.call(context||this,data)
    },

    on: (key: string, func: Function) => {
        EventEmitter.listeners[key] = func
    },

    off: (key: string) => {
        delete EventEmitter.listeners[key]
    }


}
export default EventEmitter
