import {selector} from "recoil";

const callbacks:any = {}
let socket:any = null
enum EVENT_TYPE {
    PING="PING",TEXT="TEXT",SUBSCRIBE="SUBSCRIBE"
}

export const initSocket = ()=>{
    if(socket?.readyState===WebSocket.OPEN){
        return
    }
    const token = localStorage.getItem("token")
    if(!token){
        setTimeout(()=>{
            initSocket()
        },3000)
        return
    }
    socket = new WebSocket(`wss://aa.zappernow.com/ws?token=${token}`);
    socket.onopen = () => {
        ping()
        subscribe("BTCUSD,XRPUSD,ETHUSD")
    }
    socket.onerror = () => {}
    socket.onclose = () => {
        initSocket()
    }
    socket.onmessage = async (event:any) => {
        if (event.data instanceof Blob) {
            // Handle binary data
            const blob = event.data;
            const text = await blob.text()
            for (let item in callbacks) {
                const func = callbacks[item]
                console.log(text)
                func && func(JSON.parse(text))
            }
        } else {
            console.log('Received text data:', event.data);
        }
    }
}
const isConnected = ()=>{
    return socket?.readyState===WebSocket.OPEN
}

const sendMsg = (data:any,eventType:EVENT_TYPE=EVENT_TYPE.TEXT)=>{
    if(isConnected()){
        const msg:any = {type:eventType}
        if(data){
            msg.data = data
        }
        socket?.send(JSON.stringify(msg))
    }
}

export const disconnectWebsocket = ()=>{
    if(socket){
        socket.close()
    }
}

export const addCallback = (key:string,func:Function)=>{
    callbacks[key] = func

}
let timer:any = null
const ping = ()=>{
    sendMsg(null,EVENT_TYPE.PING)
    if(timer) {
        clearTimeout(timer)
    }
    timer = setTimeout(()=>{
        ping()
    },10*1000)
}

const subscribe = (symbol:string)=>{
    sendMsg(symbol,EVENT_TYPE.SUBSCRIBE)
}
const isDisconnected = ()=>{
    return socket?.readyState===WebSocket.CLOSED||socket?.readyState===WebSocket.CLOSING
}

export const socketState = selector({
    key: 'socketState',
    get: () => {
        if(socket==null || isDisconnected()){
            initSocket()
        }
        return socket
    },
});
