const UpDownIcon = ()=> {
    return <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="currentColor">
        <path
            d="M11.8126 6.99906C11.438 7.43749 11.7033 8.18714 12.2332 8.18714H13.0609C13.4185 8.18714 13.7083 8.47696 13.7083 8.83447V12.6439C13.7083 13.0014 13.9981 13.2912 14.3556 13.2912H17.1936C17.5511 13.2912 17.841 13.0014 17.841 12.6439V8.83447C17.841 8.47695 18.1308 8.18714 18.4883 8.18714H19.4473C19.9772 8.18714 20.2425 7.43749 19.8679 6.99906L16.2608 2.77806C16.0285 2.50627 15.652 2.50627 15.4197 2.77806L11.8126 6.99906Z"
            fill="currentColor"></path>
        <path
            d="M13.5303 16.1083C13.9095 15.6738 13.6409 14.9308 13.1047 14.9308L12.2848 14.9308C11.9273 14.9308 11.6375 14.6409 11.6375 14.2834V10.4763C11.6375 10.1188 11.3477 9.82902 10.9902 9.82902H7.96155C7.60404 9.82902 7.31422 10.1188 7.31422 10.4763L7.31422 14.2834C7.31422 14.6409 7.0244 14.9308 6.66689 14.9308H5.71224C5.176 14.9308 4.90746 15.6738 5.28663 16.1083L8.98287 20.3442C9.21793 20.6136 9.59903 20.6136 9.83408 20.3442L13.5303 16.1083Z"
            fill="currentColor"></path>
        <path
            d="M10.3649 3.44716C10.8336 3.36127 11.2529 3.71706 11.2939 4.19179C11.3348 4.66652 10.9814 5.07947 10.5148 5.17583C9.85574 5.31192 9.21716 5.53828 8.61744 5.84957C7.76188 6.29367 7.00216 6.90193 6.38167 7.63964C5.76119 8.37734 5.29207 9.23003 5.00112 10.149C4.79718 10.7932 4.68357 11.4611 4.66241 12.1338C4.64743 12.61 4.30112 13.0289 3.8264 13.0699C3.35167 13.1109 2.9293 12.7587 2.93363 12.2823C2.94179 11.3831 3.0837 10.4885 3.35607 9.62822C3.71542 8.49318 4.2948 7.44005 5.06115 6.52893C5.8275 5.61781 6.7658 4.86656 7.82248 4.31807C8.62341 3.90233 9.48046 3.60925 10.3649 3.44716Z"
            fill="currentColor"></path>
        <path
            d="M13.6328 21.2814C13.1641 21.3673 12.7449 21.0115 12.7039 20.5368C12.6629 20.062 13.0163 19.6491 13.483 19.5527C14.142 19.4166 14.7806 19.1903 15.3803 18.879C16.2359 18.4349 16.9956 17.8266 17.6161 17.0889C18.2366 16.3512 18.7057 15.4985 18.9966 14.5795C19.2006 13.9353 19.3142 13.2674 19.3353 12.5948C19.3503 12.1185 19.6966 11.6996 20.1714 11.6586C20.6461 11.6177 21.0685 11.9698 21.0641 12.4463C21.056 13.3454 20.9141 14.24 20.6417 15.1003C20.2823 16.2354 19.703 17.2885 18.9366 18.1996C18.1703 19.1107 17.232 19.862 16.1753 20.4105C15.3743 20.8262 14.5173 21.1193 13.6328 21.2814Z"
            fill="currentColor"></path>
    </svg>
}
export default UpDownIcon
