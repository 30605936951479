import request from "./request";

const gameId = "123123"
export const get_cryptos = async()=>{
    // @ts-ignore
    // const headers = {'Authorization':token}
    return await request.get('/open/v1/cryptos',{})
}
export const get_account_info = async()=>{
    // @ts-ignore
    // const headers = {'Authorization':token}
    return await request.get('/open/v1/accountInfo',{})
}

export const login = (params:{username:string,password:string})=>{
    return request.post('/open/v1/login',params)
}

export const joinGame = (symbol:string,period:number,money:number)=>{
    return request.post('/open/v1/game/join',{gameId,params: {symbol, period, money}})
}

export const queryOrders = (pageNo:number=1)=>{
    return request.get(`/open/v1/game/orders?pageNo=${pageNo}&gameId=${gameId}`)
}


export const config = (symbol:string)=>{
    return request.get(`/open/v1/game/config?gameId=${gameId}&type=2&symbol=${symbol}`)
}


export const kline = (symbol:string,mill:number=new Date().getTime(),size:number=100)=>{
    return request.get(`/open/v1/kline?symbol=${symbol}&mill=${mill}&size=${size}`)
}
