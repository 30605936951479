import React, {createRef, useEffect, useRef} from "react";
import Bet from "./components/Bet";
import Kline from "./components/Kline";
import Menu from "./components/Menu";
import MobileMenu from "./components/MobileMenu";
import {useMediaQuery} from "react-responsive";
import NewBetOrder from "./components/NewBetOrder";
import TradeOrder from "./components/TradeOrder";
import {get_account_info, get_cryptos} from "./api";
import {useSetRecoilState} from "recoil";
import {accountState} from "./store/user";

const Trade = () => {
    // const [searchParams] = useSearchParams();
    const setAccountState = useSetRecoilState(accountState)
    const initData = async () => {
        const {data} = await get_account_info()
        setAccountState(data)
    }
    const isMounted = useRef(false);
    useEffect(() => {
        get_cryptos()
        isMounted.current = true;
        initData()
        return function (){
            if(isMounted.current) {
            }
        }
    }, []);
    const isTabletOrMobile = useMediaQuery({query: '(max-width: 1366px)'})
    const isHiddenBet = useMediaQuery({query: '(max-width: 1024px)'})
    const rootRef: any = createRef()
    return <div id="trade-app-root" ref={rootRef} className="trade-app-root font-primary leading-normal text-primary">
        <div className="fixed top-16 right-4 z-max flex flex-col items-end"></div>
        <div className="flex w-screen h-screen relative">
            {!isTabletOrMobile ? <Menu/> : <></>}
            <div className="flex-1 flex flex-col overflow-hidden">
                <header className="sticky z-30 px-3 top-0 s768:px-4 h-15 s768:h-17 flex items-center bg-layer4"
                        style={{boxShadow: 'rgba(0, 0, 0, 0.25) 0px 2px 4px 0px'}}>
                    {/*<div className="w-full flex items-center justify-end gap-2 s768:gap-4">*/}

                    {/*    <Link to="/login">*/}
                    {/*        <button*/}
                    {/*            className="inline-flex items-center justify-center max-w-full rounded-2 active:scale-95 outline-none transition-all whitespace-nowrap text-primary font-500 disabled:pointer-events-none disabled:opacity-50 bg-transparent h-9 px-4 py-2 text-12 shadow-none">Sign*/}
                    {/*            In*/}
                    {/*        </button>*/}
                    {/*    </Link>*/}
                    {/*    <button*/}
                    {/*        className="inline-flex items-center justify-center max-w-full rounded-2 active:scale-95 outline-none transition-all shadow whitespace-nowrap disabled:pointer-events-none disabled:opacity-50 bg-brand text-primary_brand font-700 h-9 px-4 py-2 text-12">Register*/}
                    {/*    </button>*/}
                    {/*</div>*/}
                </header>
                <div className="flex flex-1 overflow-hidden">
                    <div className="flex-1 overflow-auto">
                        <div className="flex-1 bg-layer3 overflow-x-hidden p-4">
                            <div className="flex flex-col flex-1 gap-3">
                                <div className="flex">
                                    <Kline/>
                                    {!isHiddenBet ? <Bet/> : <></>}
                                </div>
                                {isHiddenBet ? <Bet/> : <></>}
                                {isHiddenBet ? <NewBetOrder/> : <></>}
                                <div className="flex-1">
                                    <TradeOrder/>
                                    <div><span aria-hidden="true" id="headlessui-tabs-panel-:rm:" role="tabpanel"
                                               style={{
                                                   position: 'fixed',
                                                   top: '1px',
                                                   left: '1px',
                                                   width: '1px',
                                                   height: '0px',
                                                   padding: '0px',
                                                   margin: '-1px',
                                                   overflow: 'hidden',
                                                   clip: 'rect(0px, 0px, 0px, 0px)',
                                                   whiteSpace: 'nowrap',
                                                   borderWidth: '0px'
                                               }}></span><span
                                        aria-hidden="true" id="headlessui-tabs-panel-:rn:" role="tabpanel"
                                        aria-labelledby="headlessui-tabs-tab-:rj:"
                                        style={{
                                            position: 'fixed',
                                            top: '1px',
                                            left: '1px',
                                            width: '1px',
                                            height: '0px',
                                            padding: '0px',
                                            margin: '-1px',
                                            overflow: 'hidden',
                                            clip: 'rect(0px, 0px, 0px, 0px)',
                                            whiteSpace: 'nowrap',
                                            borderWidth: '0px'
                                        }}></span>
                                        <div id="headlessui-tabs-panel-:ro:" role="tabpanel"
                                             data-headlessui-state="selected"
                                             aria-labelledby="headlessui-tabs-tab-:rk:">
                                            <div className="relative min-h-50 max-h-[inherit]">

                                            </div>
                                        </div>
                                        <span aria-hidden="true" id="headlessui-tabs-panel-:rp:" role="tabpanel"
                                              aria-labelledby="headlessui-tabs-tab-:rl:"
                                              style={{
                                                  position: 'fixed',
                                                  top: '1px',
                                                  left: '1px',
                                                  width: '1px',
                                                  height: '0px',
                                                  padding: '0px',
                                                  margin: '-1px',
                                                  overflow: 'hidden',
                                                  clip: 'rect(0px, 0px, 0px, 0px)',
                                                  whiteSpace: 'nowrap',
                                                  borderWidth: '0px'
                                              }}></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <footer className="py-18 px-12 bg-layer2">
                            <div className="max-w-[1140px] s1920:max-w-[1600px] mx-auto">
                                <div className="flex s768:justify-center s1024:justify-between flex-wrap gap-4 mb-8">
                                    <div className="flex flex-col gap-2.5">
                                        <div
                                            className="font-500 text-14 leading-5 px-4 py-2.5 mb-4 bg-white/10 rounded-2">Transaction
                                            Overview
                                        </div>
                                        <div className="text-14 text-[#C9CFDD] hover:text-brand cursor-pointer">Trading
                                            Platform
                                        </div>
                                        <div className="text-14 text-[#C9CFDD] hover:text-brand cursor-pointer">Product
                                            Description
                                        </div>
                                        <div className="text-14 text-[#C9CFDD] hover:text-brand cursor-pointer">User
                                            Agreement &amp; Terms
                                        </div>
                                        <div
                                            className="text-14 text-[#C9CFDD] hover:text-brand cursor-pointer">AML &amp; KYC
                                            Policy
                                        </div>
                                        <div className="text-14 text-[#C9CFDD] hover:text-brand cursor-pointer">Privacy
                                            Policy
                                        </div>
                                    </div>
                                    <div className="flex flex-col gap-2.5">
                                        <div
                                            className="font-500 text-14 leading-5 px-4 py-2.5 mb-4 bg-white/10 rounded-2">Service
                                            Guide
                                        </div>
                                        <div className="text-14 text-[#C9CFDD] hover:text-brand cursor-pointer">DeTrade
                                            Overview
                                        </div>
                                        <div className="text-14 text-[#C9CFDD] hover:text-brand cursor-pointer">Deposits
                                            And
                                            Withdrawals
                                        </div>
                                        <div className="text-14 text-[#C9CFDD] hover:text-brand cursor-pointer">Partner
                                            Program
                                        </div>
                                        <div className="text-14 text-[#C9CFDD] hover:text-brand cursor-pointer">Contact
                                            Us
                                        </div>
                                    </div>
                                    <div className="flex flex-col gap-2.5">
                                        <div
                                            className="font-500 text-14 leading-5 px-4 py-2.5 mb-4 bg-white/10 rounded-2">Beginners
                                        </div>
                                        <div className="text-14 text-[#C9CFDD] hover:text-brand cursor-pointer">FAQ
                                        </div>
                                        <div className="text-14 text-[#C9CFDD] hover:text-brand cursor-pointer">Quick
                                            Demo
                                        </div>
                                    </div>
                                    <div className="flex flex-col gap-2.5">
                                        <div
                                            className="font-500 text-14 leading-5 px-4 py-2.5 mb-4 bg-white/10 rounded-2">Existing
                                            Customers
                                        </div>
                                        <div
                                            className="text-14 text-[#C9CFDD] hover:text-brand cursor-pointer">Login
                                        </div>
                                        <div className="text-14 text-[#C9CFDD] hover:text-brand cursor-pointer">Forgot
                                            the
                                            Password
                                        </div>
                                        <div className="text-14 text-[#C9CFDD] hover:text-brand cursor-pointer">Personal
                                            Center
                                        </div>
                                    </div>
                                    <div>
                                        <div
                                            className="mb-2.5 font-500 text-14 leading-5 px-4 py-2.5 bg-white/10 rounded-2">Use
                                            your phone to trade quickly.
                                        </div>
                                        <div className="flex items-center justify-around bg-[#0F1725] rounded-2"><img
                                            src="/static/media/footer_mobile_tips_pc.d6e5a0e4772c25ff0d07.png"
                                            className="w-24" alt="mobile"/>
                                            <button
                                                className="inline-flex items-center justify-center max-w-full active:scale-95 outline-none transition-all shadow whitespace-nowrap disabled:pointer-events-none disabled:opacity-50 bg-brand text-primary_brand font-700 h-9 px-4 py-2 text-14 rounded-full">Learn
                                                More
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-col gap-8">
                                    <div className="h-px bg-white/10"></div>
                                    <div className="text-12 text-secondary"><p className="p-1">Brokerage Products and
                                        Services
                                        offered by Nexus Play LLC.</p><p className="p-1">Nexus Play LLC, a Brokerage
                                        Company
                                        authorised and regulated by the Mwali International Services Authority
                                        (M.I.S.A.) under the License #BFX2024137.</p><p className="p-1">Nexus Play LLC
                                        is a
                                        Brokerage that provides self-directed investors with brokerage services, and
                                        does not make recommendations or offer investment, financial, legal or tax
                                        advice. Online trading carries inherent risks, including fluctuations in system
                                        response and access times due to market conditions, system performance, and
                                        other factors. Before trading, consult an independent, licensed financial
                                        advisor and ensure you have the necessary risk appetite, experience, and
                                        knowledge. Under no circumstances shall Nexus Play LLC have any liability to any
                                        person or entity for any direct, indirect, special, consequential or incidental
                                        damages whatsoever. </p><p className="p-1">The products and services offered by
                                        Nexus Play LLC are not intended for residents of the United States, European
                                        Union, or any other jurisdictions where such services and products are
                                        prohibited by law. Nexus Play LLC does not solicit business or provide brokerage
                                        services to individuals or entities in these regions. It is the responsibility
                                        of potential clients to be aware of and comply with any applicable laws and
                                        regulations in their respective jurisdictions before engaging in online trading.
                                        By accessing this website and utilizing our services, you confirm that you are
                                        not a resident of the United States, European Union, or any other jurisdiction
                                        where our services are restricted.</p><p className="p-1">All brokerage activity
                                        on
                                        this website is provided by Nexus Play LLC.</p><p className="p-1">Copyright ©
                                        2024
                                        Nexus Play LLC., All rights reserved.</p></div>
                                </div>
                            </div>
                        </footer>
                    </div>
                </div>
                {isTabletOrMobile ? <MobileMenu/> : <></>}
            </div>
        </div>
        <div id="custom-portal-root">
        </div>
    </div>
}

export default Trade
