import {atom} from "recoil";

export const currentCryptoState = atom({
    key: 'current',
    default: {},
});
// export const accountState = selector({
//     key: 'account',
//     get: async ({get}) => {
//         const {data} = await get_account_info();
//         return data[0];
//     },
// });
