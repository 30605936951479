import {Popover, PopoverButton, PopoverPanel} from "@headlessui/react";
import ArrowTop from "./ArrowTop";
import React, {useState} from "react";
import SettingIcon from "./SettingIcon";

const Setting = ()=>{
    const [open] = useState(false)
    return <Popover className="relative bg-layer4 rounded-3 h-8 cursor-pointer text-secondary hover:text-primary transition-colors">
        <PopoverButton className="outline-none bg-transparent p-2">
            <SettingIcon/>
        </PopoverButton>
        <div>
            {open ? <ArrowTop/> : <></>}
            <PopoverPanel
                data-open={open}
                className="absolute z-40 bg-layer5 light:bg-white shadow rounded-2 text-secondary overflow-auto mt-[14px] p-2 s768:p-3 max-h-100">
                <div className="w-80 text-14">
                    <div className="grid grid-cols-3 text-secondary"
                         style={{gridTemplateColumns: 'minmax(0px, 1fr) minmax(0px, 1fr) 80px'}}>
                        <div className="pl-3 py-1">Pair</div>
                        <div className="px-3 py-1 text-right">Price</div>
                        <div className="pr-3 py-1 text-right">24h</div>
                    </div>
                    <div
                        className="grid grid-cols-3 text-right bg-transparent cursor-pointer hover:bg-layer6 light:hover:bg-layer5 rounded"
                        style={{gridTemplateColumns: 'minmax(0px, 1fr) minmax(0px, 1fr) 80px'}}>
                        <div className="flex items-center pl-3 py-2"><span
                            className="block overflow-hidden shrink-0 mr-1 w-5 h-5 rounded-full z-10 lazy-load-image-background  lazy-load-image-loaded"><img
                            src="https://currency-trade.s3.ap-east-1.amazonaws.com/icons/BTC.png"
                            alt=""
                            className="w-full h-full object-cover"/></span>
                            <div>BTC/USD</div>
                        </div>
                        <div className="text-primary px-3 py-2">59,604.33</div>
                        <div className="text-up pr-3 py-2">0.87%</div>
                    </div>
                </div>
            </PopoverPanel>
        </div>
    </Popover>
}
export default Setting
